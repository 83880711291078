<template>
    <el-row id="advance" class="text-direction">
        <el-row >
            <el-col :span="20" :push="2">
                <feature-head text="版图优势"/>
            </el-col>
        </el-row>
        <!--    professional-group    -->
        <el-row type="flex" justify="center" class="advance-item">
            <el-col :span="20">
                <el-row class="row-flex" justify="space-between">
                    <el-col :xs="24" :sm="24" :md="12" class="show-md-and-up">
                        <h4 class="header">经验丰富的团队</h4>
                        <p class="advance-desc">公司拥有一支技术精湛和经验丰富的专业团队，现拥有20多名专业技术开发人员，在JAVA WEB、ANDRIOD 、IOS等技术平台上积累了丰富的经验</p>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12" class="advance-img">
                        <img src="@/assets/images/advance/professional-group.png" alt="">
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12" class="show-md-and-down">
                        <h4 class="header">经验丰富的团队</h4>
                        <p class="advance-desc">公司拥有一支技术精湛和经验丰富的专业团队，现拥有20多名专业技术开发人员，在JAVA WEB、ANDRIOD 、IOS等技术平台上积累了丰富的经验</p>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <!--    personal-service    -->
        <el-row type="flex" justify="center" class="advance-item">
            <el-col :span="20">
                <el-row class="row-flex" justify="space-between">
                    <el-col :md="12" class="advance-img">
                        <img src="@/assets/images/advance/personal-service-1.png" alt="">
                    </el-col>
                    <el-col :md="12">
                        <h4 class="header">专业、持久的个性化服务</h4>
                        <p class="advance-desc">版图网络以“诚信为本、务实求己、勇于创新、开拓发展”的基本价值理念，以资源优化和技术创新为企业的发展之道，依托公司优秀的开发管理人才， 为客户提供系统的定制、开发以及解决方案的制定、技术与人才外包等方面的专业、持久的个性化服务</p>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <!--    best-quantity    -->
        <el-row type="flex" justify="center" class="advance-item">
            <el-col :span="20">
                <el-row class="row-flex" justify="space-between">
                    <el-col :md="12" class="show-md-and-up">
                        <h4 class="header">超高的服务质量</h4>
                        <p class="advance-desc">自成立以来，与多家大中型企业合作开发信用体系，物流、银行、水利、电商等领域的软件，同时以完善的管理体系、严谨的开发流程、超高的开发质量， 获得了客户高度的认可和好评。</p>
                    </el-col>
                    <el-col :md="12" class="advance-img">
                        <img src="@/assets/images/advance/best-quantity.png" alt="">
                    </el-col>
                    <el-col :md="12" class="show-md-and-down">
                        <h4 class="header">超高的开发质量</h4>
                        <p class="advance-desc">自成立以来，与多家大中型企业合作开发信用体系，物流、银行、水利、电商等领域的软件，同时以完善的管理体系、严谨的开发流程、超高的开发质量， 获得了客户高度的认可和好评。</p>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <!--    creative    -->
        <el-row type="flex" justify="center" class="advance-item">
            <el-col :span="20">
                <el-row class="row-flex" justify="space-between">
                    <el-col :md="12" class="advance-img">
                        <img src="@/assets/images/advance/creative.png" alt="">
                    </el-col>
                    <el-col :md="12">
                        <h4 class="header">秉承勇于创新的精神</h4>
                        <p class="advance-desc">版图网络 秉承勇于创新的精神，为客户提供可靠的、富有创新精神的解决方案，</p>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </el-row>
</template>

<script>
    import FeatureHead from "../components/common/FeatureHead";
    export default {
        name: "Advance",
        components: {
            FeatureHead
        }
    }
</script>

<style scoped>
    #advance .advance-item {
        margin-bottom: 50px;
    }

    #advance .header {
        padding: 0;
        margin: 10px 0;
        font-size: 22px;
        font-weight: 400;
        color: #797979;
    }

    #advance .advance-desc {
        font-size: 14px;
        color: #797979;
        line-height: 1.5;
    }

    #advance .advance-img {
        text-align: center;
    }

    #advance .advance-img img{
        width: 230px
    }
</style>
